import React from 'react';
import { Link } from 'react-router-dom';
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';
import $ from 'jquery';
import Toast from './Toast';
import Badge from './Badge';
import VehicleContext from '../../Context/VehicleContext';

class PopSystemSearch extends React.Component {
    static contextType = VehicleContext
    state = {
        userId: functions.sessionGuard(),
        info: '',
        hint: '',
        userResults: false,
        vehicleResults: false,
        sessionResults: false,
        invoiceResults: false,
        toast: false
    }

    onSearch = (event) => {
        event.preventDefault();
       this.findResults();
    }

    findResults=()=>{
        this.findUser();
        this.findVehicle();
    }

    onChangeHint = (event) => {
        this.setState({ hint: event.target.value }, ()=>this.findResults());
    }

    findUser = async () => {
        if (this.state.hint.length > 0) {
            const server_response = await ajax.searchUser(this.state.hint, this.state.userId);
            if (server_response.status === "OK") {
                this.setState({
                    userResults: server_response.details.content
                })
            } else if (server_response.status === "Fail") {
                this.setState({
                    userResults: false
                })
            }

        } else {
            this.setState({
                userResults: false
            })
        }
    }

    findVehicle = async () => {
        if (this.state.hint.length > 0) {
            const server_response = await ajax.searchVehicle(this.state.hint, this.state.userId);
            if (server_response.status === "OK") {
                this.setState({
                    vehicleResults: server_response.details.content,
                    toast: <Toast
                        message={server_response.details.message}
                        onClose={() => this.resetToast()} />
                })
            } else if (server_response.status === "Fail") {
                this.setState({
                    vehicleResults: false,
                    toast: <Toast
                        type={4}
                        message={server_response.details.message}
                        onClose={() => this.resetToast()} />
                })
            }

        } else {
            this.setState({
                vehicleResults: false
            })
        }
    }


    hideFinder = () => {
        $("#mdl-sys-finder").modal('hide');
    }


    resetToast = () => {
        this.setState({
            toast: false
        })
    }

    handleGotoVehicle=(plateNumber)=>{
        const {setPlateNumber} = this.context
        setPlateNumber(plateNumber)
        this.hideFinder();

    }

    render() {

        return (
            <div className="modal " id="mdl-sys-finder">
                <div className="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered" role="document">

                    <div className="modal-content modal-content-demo">
                        <div className="modal-header">
                            <h6 className="modal-title">
                                System Find
                        &nbsp;<i className="fe fe-search text-secondary" />
                            </h6>
                            {this.state.toast}
                        </div>
                        <div className="modal-body">
                            {this.state.info}
                            <div className="form-group">

                                <form method="post" onSubmit={this.onSearch}>
                                    <input
                                        type="text"
                                        className="form-control input-lg"
                                        placeholder="Enter keywords...."
                                        value={this.state.hint}
                                        onFocus={functions.handleFocus}
                                        onChange={this.onChangeHint} />
                                </form>
                            </div>


                            <div className="table-responsive">
                                <Link to="/vehicles/all-vehicles">
                                    <Badge value="See All Vehicles" type={6}/>
                                </Link> &nbsp;&nbsp;
                                <Link to="/users/all-users" onClick={this.hideFinder}>
                                    <Badge value="See All Users" type={6}/>
                                </Link>

                                {this.state.vehicleResults && <table className="table mg-b-0">
                                    <thead>
                                        <tr>
                                            <th colSpan="3">
                                            Vehicle Results
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.vehicleResults.map((item, key) =>
                                            <tr key={key}>
                                                <td className="bd-t-0">
                                                    <h6 className="mg-b-0 tx-gray-500">{item.plate_number}</h6>
                                                    <small className="tx-11 tx-gray-700">
                                                        Since : {item.created_at}
                                                    </small>
                                                    <br />
                                                    <small className="tx-11 tx-gray-700">
                                                        VId:{item.vehicle_id}
                                                    </small>&nbsp;&nbsp;

                                                    {item.white_list && <Badge value="White Listed" type={3}/>}
                                                    {item.clamped && <Badge 
                                                                            value={item.clamping_status} 
                                                                            type={2} 
                                                                            title={`Clamp info: ${item.plate_number} - clamped by ${item.clamp_info.clamped_by.full_name}. Reason: ${item.clamp_info.clamped_for.violation_name} dated ${item.clamp_info.created_at.when} along ${item.clamp_info.clamping_location.street.street_name} - ${item.clamp_info.clamping_location.location_name}`}
                                                                            
                                                                            />}
                                                    <br />
                                                    <small className="tx-11 tx-gray-700">
                                                        Balance: UGX. {item.balance.amount_c}
                                                    </small>&nbsp;&nbsp;

                                                    <br />
                                                </td>
                                                <td className="bd-t-0">
                                                    <h6 className="mg-b-0">Vehicle Info</h6>
                                                    <small className="tx-14 tx-gray-900">Plate Number: {item.plate_number}</small><br></br>
                                                    <small className="tx-14 tx-gray-900">Is VIP: {item.is_vip}</small><br></br>


                                                    <Link to={
                                                        {
                                                            pathname: `/vehicles/profile/${item.plate_number}`,
                                                            state: { plateNumber: item.plate_number }
                                                        }
                                                    }
                                                        className="tx-14"
                                                        onClick={()=>this.handleGotoVehicle(item.plate_number)}
                                                        replace>
                                                        Go to vehicle
                                        </Link>

                                                </td>


                                                <td className="bd-t-0">
                                                    <h6 className="mg-b-0">Client Info</h6>
                                                    <small className="tx-14 tx-gray-900">Name: {item.client.full_name}</small><br></br>
                                                    <small className="tx-14 tx-gray-900">Phone: {item.client.phone_number}</small><br></br>


                                                </td>



                                            </tr>)}
                                    </tbody>
                                </table>}

                                {this.state.userResults && <table className="table mg-b-0">
                                    <thead>
                                        <tr>
                                            <th colSpan="2">
                                            User Results
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.userResults.map((item, key) =>

                                            <tr key={key}>
                                                <td className="bd-t-0 wd-20">
                                                    <div className="main-img-user">
                                                        <img alt="avatar" src={item.profile_photo.file_path} />
                                                    </div>
                                                    <small className="tx-11 tx-gray-700">{item.user_role.role_name + "-" + item.user_id}</small><br></br>
                                                </td>
                                                <td className="bd-t-0">
                                                    <h6 className="mg-b-0">About</h6>
                                                    <small className="tx-14 tx-gray-900">Name: {item.first_name + ' ' + item.last_name}</small><br></br>
                                                    <small className="tx-14 tx-gray-900">Phone: {item.phone_number}</small><br></br>
                                                    <small className="tx-14 tx-gray-900">Username: {item.username}</small><br></br>

                                                    <Link
                                                        to={
                                                            {
                                                                pathname: `/users/profile/${item.username}`,
                                                                state: { username: item.username }
                                                            }
                                                        }
                                                        onClick={this.hideFinder}
                                                        className="tx-14"
                                                        replace>View Profile</Link>

                                                </td>

                                            </tr>

                                        )}




                                    </tbody>
                                </table>}
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

export default PopSystemSearch;
