import React from 'react';


class Badge extends React.Component {
  state={
    types: ['badge-success', 'badge-warning', 'badge-danger', 'badge-primary', 'badge-secondary', 'badge-light', 'badge-dark'],
    type: this.props.type || 0
  }

  render(){
    
      return (
        <span title={this.props.title} className={`badge badge-pill  ${this.state.types[this.state.type]} link`}>{this.props.value}</span>
      );
    
 
  }
}

export default Badge;