import React, { Component, useEffect } from 'react';
import { FinanceProvider } from './FinanceContext';
import { AuthConsumer, AuthProvider } from './AuthContext';
import { NotificationProvider } from './NotificationContext';
import { OperationProvider } from './OperationContext';
import { ConfigurationProvider } from './ConfigurationContext';
import { CashierProvider } from './CashierContext';
import { ManageUserProvider } from './ManageUserContext';
import { MapProvider } from './MapContext';
import { ThemeProvider } from './ThemeContext';
import { ReservationProvider } from './ReservationContext';
import { FieldProvider } from './FieldContext';
import { MigrationProvider } from './MigrationContext';
import { PaymentProvider } from './PaymentContext';
import { WrongParkingProvider } from './ContraventionContext/WrongParkingContext';
import { PaymentDueProvider } from './ContraventionContext/PaymentDueContext';
// import { FireBaseProvider } from './FireBaseContext/FireBaseContext';
import { XReportProvider } from './Reports/XReportContext';
import { ClampingProvider } from './Clamping/ClampingContext';
import { WhiteListProvider } from './WhiteList/WhiteListContext';
// import { TowingProvider } from './ContraventionContext/TowingContext';
import { SpaceInventoryProvider } from './Reports/SpaceInventoryContext';
import { OccupancyProvider } from './Reports/OccupancyContext';
import { PaymentsTrackingProvider } from './Reports/PaymentsTrackingContext';
import { SupervisorProvider } from './SupervisorContext';
import { VehicleProvider } from './VehicleContext';
import { PaperTicketProvider } from './PaperTicketContext';
import { MobileMoneyProvider } from './MobileMoneyContext';
import { BranchProvider } from './BranchContext';
import { LocationMappingConsumer, LocationMappingProvider } from './LocationMappingContext';
import ParkingMigrationContext, { ParkingMigrationProvider } from './Migration/ParkingMigrationContext';
import { PaymentMigrationProvider } from './Migration/PaymentMigrationContext';
import { StickerProvider } from './StickerContext';
import { BranchSessionProvider } from './BranchSessionContext';
import { VirtualTicketProvider } from './VirtualTicketContext';
import { AnalyticsProvider } from './Reports/AnalyticsContext';
import { VIPListProvider } from './VIP/VIPListContext';
import { ParkingsProvider } from './ParkingsContext';
import { ContraventionTypeProvider } from './ContraventionContext/ContraventionTypeContext';
import { EnforcementProvider } from './ContraventionContext/EnforcementContext';
import { ParkingViolationProvider } from './Violation/ParkingViolationContext';
import { NonPaymentProvider } from './Violation/NonPaymentContext';
import { TowingProvider } from './Violation/TowingContext';
import { ViolationReportProvider } from './Violation/ViolationReportContext';
import { CobraReportProvider } from './Violation/CobraReportContext';
import { ZuluReportProvider } from './Violation/ZuluReportContext';
import { BranchAnalysisProvider } from './Violation/BranchAnalysisContext';
import { SupervisorReportProvider } from './SupervisorReportContext';
import { FiscalReceiptProvider } from './Efris/FiscalReceiptContext';
import { EfrisHistoryProvider } from './Efris/EfrisHistoryContext';
import { DemandNoteProvider } from './Finance/DemandNoteContext';
import { ClampHistoryProvider } from './Violation/ClampHistoryContext';


const SuperProvider = (props) => {

    return (
        <AuthProvider>
            <AuthConsumer>
                {authProps => {
                    if (authProps.permissionList) {
                        return (
                            <ThemeProvider auth={authProps}>
                                <ConfigurationProvider auth={authProps}>
                                    <ManageUserProvider auth={authProps}>
                                        <NotificationProvider auth={authProps}>
                                            <OperationProvider auth={authProps}>
                                                <ReservationProvider auth={authProps}>
                                                    <FinanceProvider auth={authProps}>
                                                        <CashierProvider auth={authProps}>
                                                            <MapProvider auth={authProps}>
                                                                <FieldProvider>
                                                                    <MigrationProvider>
                                                                        <PaymentProvider>
                                                                            {/* <WrongParkingProvider auth={authProps}>
                                                                                <PaymentDueProvider auth={authProps}> */}
                                                                                    {/* <FireBaseProvider auth={authProps}> */}
                                                                                    {/* <TowingProvider auth={authProps}> */}
                                                                                        <WhiteListProvider auth={authProps}>
                                                                                            <VIPListProvider>
                                                                                                {/* <ClampingProvider auth={authProps}> */}
                                                                                                    <XReportProvider auth={authProps}>
                                                                                                        <SpaceInventoryProvider auth={authProps}>
                                                                                                            <OccupancyProvider auth={authProps}>
                                                                                                                <PaymentsTrackingProvider auth={authProps}>
                                                                                                                    <SupervisorProvider>
                                                                                                                        <VehicleProvider>
                                                                                                                            <BranchProvider>
                                                                                                                                <PaperTicketProvider>
                                                                                                                                    <MobileMoneyProvider>

                                                                                                                                        <LocationMappingProvider>
                                                                                                                                            <ParkingMigrationProvider>
                                                                                                                                                <PaymentMigrationProvider>
                                                                                                                                                    <StickerProvider>
                                                                                                                                                        <BranchSessionProvider>
                                                                                                                                                            <VirtualTicketProvider>
                                                                                                                                                                <AnalyticsProvider>
                                                                                                                                                                    <ParkingsProvider>
                                                                                                                                                                        {/* <ContraventionTypeProvider> */}
                                                                                                                                                                            {/* <EnforcementProvider> */}
                                                                                                                                                                                <ParkingViolationProvider>
                                                                                                                                                                                    <NonPaymentProvider>
                                                                                                                                                                                        <TowingProvider>
                                                                                                                                                                                            <ViolationReportProvider>
                                                                                                                                                                                                <CobraReportProvider>
                                                                                                                                                                                                    <ZuluReportProvider>
                                                                                                                                                                                                        <BranchAnalysisProvider>
                                                                                                                                                                                                          <SupervisorReportProvider>
                                                                                                                                                                                                            <FiscalReceiptProvider>
                                                                                                                                                                                                                <EfrisHistoryProvider>
                                                                                                                                                                                                                    <DemandNoteProvider>
                                                                                                                                                                                                                        <ClampHistoryProvider>
                                                                                                                                                                                                                        {props.children}
                                                                                                                                                                                                                        </ClampHistoryProvider>
                                                                                                                                                                                                                    </DemandNoteProvider>
                                                                                                                                                                                                                </EfrisHistoryProvider>

                                                                                                                                                                                                            </FiscalReceiptProvider>
                                                                                                                                                                                                          </SupervisorReportProvider>
                                                                                                                                                                                                        </BranchAnalysisProvider>
                                                                                                                                                                                                    </ZuluReportProvider>
                                                                                                                                                                                                </CobraReportProvider>
                                                                                                                                                                                            </ViolationReportProvider>
                                                                                                                                                                                        </TowingProvider>
                                                                                                                                                                                    </NonPaymentProvider>
                                                                                                                                                                                </ParkingViolationProvider>

                                                                                                                                                                                
                                                                                                                                                                            {/* </EnforcementProvider> */}

                                                                                                                                                                        {/* </ContraventionTypeProvider> */}
                                                                                                                                                                    </ParkingsProvider>

                                                                                                                                                                </AnalyticsProvider>

                                                                                                                                                            </VirtualTicketProvider>

                                                                                                                                                        </BranchSessionProvider>

                                                                                                                                                    </StickerProvider>
                                                                                                                                                </PaymentMigrationProvider>
                                                                                                                                            </ParkingMigrationProvider>
                                                                                                                                        </LocationMappingProvider>


                                                                                                                                    </MobileMoneyProvider>
                                                                                                                                </PaperTicketProvider>
                                                                                                                            </BranchProvider>
                                                                                                                        </VehicleProvider>
                                                                                                                    </SupervisorProvider>
                                                                                                                </PaymentsTrackingProvider>
                                                                                                            </OccupancyProvider>
                                                                                                        </SpaceInventoryProvider>
                                                                                                    </XReportProvider>
                                                                                                {/* </ClampingProvider> */}
                                                                                            </VIPListProvider>
                                                                                        </WhiteListProvider>
                                                                                    {/* </TowingProvider> */}
                                                                                    {/* </FireBaseProvider> */}
                                                                                {/* </PaymentDueProvider>
                                                                            </WrongParkingProvider> */}
                                                                        </PaymentProvider>
                                                                    </MigrationProvider>
                                                                </FieldProvider>
                                                            </MapProvider>
                                                        </CashierProvider>
                                                    </FinanceProvider>
                                                </ReservationProvider>
                                            </OperationProvider>
                                        </NotificationProvider>
                                    </ManageUserProvider>
                                </ConfigurationProvider>
                            </ThemeProvider>
                        )
                    } else {
                        return props.children
                    }
                }}
            </AuthConsumer>

        </AuthProvider >

    )
}

export default SuperProvider;